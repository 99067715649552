<template>
  <div class="container pt-24 mx-auto lg:pt-32">
    <div class="flex flex-wrap justify-center w-full bg-white">
      <div class="text-black w-12x4 md:w-2/4 ">
        <div class="flex flex-wrap justify-center">
          <div class="px-4">
            <img
              src="img/makro.jpg"
              alt="..."
              class="h-auto max-w-full align-middle border-none shadow lg:mt-10 rounded-2xl"
            />
          </div>
        </div>
      </div>
      <div class="text-black w-12x4 md:w-2/4">
        <article class="p-5 m-1 mt-10 text-left bg-gray-200 rounded-2xl">
          <!--<h2 class="titulo-pagina">
            trayectoria
          </h2>-->
          <p class="parrafo-pagina">
            Todo buen diseño comienza con la búsqueda, la definición y el entendimiento de las costumbres y necesidades del futuro usuario, sea este particular o institucional.
          </p>
          <p class="parrafo-pagina">
            La elección de cada parte del todo, sus formas, sus tamaños, sus proporciones, texturas, colores, materialidad y resistencias persigue encontrar la armonía del conjunto dentro de criterios de racionalidad, funcionalidad y temporalidad, sin perder de vista alcanzar la satisfacción del usuario.
          </p>
          <p class="parrafo-pagina">
            Logrado ese equilibrio intangible, recorremos virtualmente juntos su interior y también su exterior para verificar que el todo satisface lo imaginado.
          </p>
          <p class="parrafo-pagina">
            La ejecución de lo diseñado y lo proyectado no comienza sin antes realizar una planificación global que abarque desde los suministros de materiales y equipos, los distintos contratistas, actores externos involucrados (particulares, organismos oficiales, financieros), como así también una planificación del flujo financiero que nos permita dimensionar los tiempos de ejecución y establecer la logística de la obra. A su vez, esta planificación global se complementa con planificaciones focalizadas de cada etapa para detectar tempranamente riesgos y eventos negativos con el objeto de poder gestionar los mismos.
          </p>
          <p class="parrafo-pagina">
Esta metodología nos permite aseverar que los tiempos totales insumidos entre la elaboración de un proyecto no standarizado y personalizado según gustos, necesidades y costumbres del comitente y la entrega de la obra llave en mano son prácticamente iguales entre un sistema constructivo tradicional y otro sistema industrializado en la medida que se tenga una definición anticipada de todos los requerimientos e insumos y una rigurosa y exhaustiva planificación. 
          </p>
          <p class="parrafo-pagina">
            Del mismo modo y con la misma responsabilidad dimensionamos económicamente el todo a partir del análisis preciso de cada componente y subcomponente de la obra, permitiéndonos garantizar inalterable el costo de la futura construcción en el transcurso del tiempo.
          </p>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>
